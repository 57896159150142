@import '../custom';
@import './headers';
@import './tabs';
@import './footer';
@import './plans';
@import './buttons';
@import './modals';

.nightowl-body {
  background: var(--surface);
  color: var(--on-surface);
}

.nightowl-login-paper {
  margin-top: 200px !important;
  padding-bottom: 25px;
}

// MUI - TextField
.nightowl-text-field {
  .MuiInputBase-input {
    color: var(--on-surface);
  }

  fieldset {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-color: var(--outline);
  }

  label {
    color: var(--on-surface);
  }

  // Inner text styles of input
  .MuiOutlinedInput-input {
    color: var(--on-surface);
  }

  // hover
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--accent-1) !important;
  }

  // focused
  & .Mui-focused fieldset {
    border-color: var(--accent-1) !important;
  }

  // focused label
  & label.Mui-focused {
    color: var(--on-surface);
  }

  // scoping the autofill fixes to NO only
  // Autocomplete in chrome has a default style that blows up the dark UI, this resets it
  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
    font-size: 16px;
  }
}

// Screen - Login
.nightowl-login-logo {
  max-width: 400px;
  margin-bottom: 20px;
}

%button-defaults {
  background-color: var(--primary) !important;
  color: var(--on-primary) !important;
  border-radius: 30px !important;
  padding: 10px !important;
}

.nightowl-login-button {
  @extend %button-defaults;
}

.nightowl-login-button-disabled {
  @extend %button-defaults;
  opacity: 0.5;
}

.nightowl-login-button-inverse {
  @extend %button-defaults;
  color: var(--primary) !important;
  background: none !important;
}

.nightowl-header-logo {
  flex-grow: 1;
  height: 70px;
  padding: 10px;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 150px;
}

.nightowl-show-hide-btn {
  position: absolute;
  right: 0;
  top: 8px;
  background: none;
  border: none;
  color: var(--on-surface);
  z-index: 1;
}

.nightowl-screen-bg {
  background-color: var(--surface);
  height: 100vh;
  position: absolute;
  max-width: 100vw !important;
}

.nightowl-forgot-password-link {
  color: var(--primary);
  margin: 24px 0 0 0;
  display: block;
  text-decoration: none;
}

// Screen - Forgot Password
.nightowl-forgot-password {
  h4 {
    font-size: 28px;
    font-weight: 400;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  }
}

.nightowl-forgot-password-content {
  text-align: center;
  padding: 56px 48px;
  max-width: 582px;
  margin: 75px auto 0 auto;
  img {
    max-width: 150px;
  }
}

.nightowl-button-link {
  text-decoration: none;
  display: block;
  color: var(--primary);
  margin: 24px 0;
  opacity: 0.5;
}

.nightowl-empty-layout {
  background-color: var(--surface);
  color: var(--on-surface);
  width: 100vw;
  height: 100vh;
  position: absolute; // @TODO setting a margin on lower elements offsets the page background.. this was carried over from Geeni and needs to be fixed at a higher level
  top: 0;
  left: 0;
}

.nightowl-email-verification-image {
  max-width: 150px;
}

.nightowl-plan-device-text {
  padding-top: 25px;
  padding-bottom: 12px;
  padding-left: 174px;
  font-weight: bold;
  & p {
    margin: 0;
  }
}

.nightowl-subscriptions-control {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  height: 100%;
  background-color: var(--surface);
  color: var(--on-surface);
  font-size: 16px;
}

.nightowl-subscriptions-control-swap-order {
  display: flex;
  flex-direction: column-reverse !important;
  width: 100%;
  height: 100%;
}

.nightowl-device-image {
  height: 65px;
}

.nightowl-payment-details-header {
  background: var(--surface-dim) !important;
  color: var(--on-surface) !important;
}

// payment details footer after first sign-in and selecting a plan
.nightowl-body {
  .summary-order-footer.MuiPaper-root {
    background: var(--surface);
    color: var(--on-surface);
  }
}

#nightowl-back-btn,
#nightowl-ok-btn,
#nightowl-keep-current-btn {
  color: var(--on-surface) !important;
}

// @TODO ALL CSS BELOW HAS BEEN UNWORKED FOR NIGHT OWL
$geeni-dark-gray: #626262;
$merkury-green: #37766f;

.geeni-error-status-page h5 {
  font-family: 'All Round Gothic' !important;
  font-weight: bolder;
}

.geeni-tab,
.geeni-font h5,
.geeni-error-status-page p {
  font-family: 'Avenir Next Rounded' !important;
}

.geeni-bg {
  background-color: $white !important;
}

.geeni-error-status-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 500px;
  min-width: 200px;
  min-height: 200px;
}

.geeni-empty-layout {
  background-color: $merkury-green !important;
  width: 100%;
  height: 100%;
  max-width: 100vw !important;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.geeni-empty-layout.white {
  background-color: $white !important;
}

.geeni-migration-model {
  background-color: $merkury-green !important;
  color: #fff !important;
}

.geeni-ribbon {
  background-color: $merkury-green !important;
  color: $white !important;
}

.geeni-promo-code-error {
  width: 244px;
  color: $red;
}

.geeni-promo-code-valid {
  width: 244px;
  color: $merkury-green;
}

.geeni-primary {
  color: $merkury-green !important;
  background-color: transparent !important;
  font-weight: bold !important;
}

.geeni-btn-text:hover {
  text-decoration: underline;
}

.geeni-text-primary {
  color: $black !important;
  font-family: 'Avenir Next Rounded' !important;
}

.geeni-checkbox {
  color: $merkury-green !important;
}

.geeni-tab:hover {
  // background-color: $green-hover;
}

.geeni-tabs-secondary {
  color: $white !important;
  background-color: $merkury-green !important;
}

.geeni-text-common {
  color: $black;
  font-weight: 500 !important;
}

.geeni-text-b {
  font-weight: bold !important;
}

.geeni-error-text {
  color: $red;
}

.geeni-form-label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bolder;
  margin-left: 4px;
  color: #0000008a;
}

.geeni-form-label-error {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bolder;
  margin-left: 4px;
  color: $red;
}

.geeni-button-progress {
  position: relative;
  margin: 15px;
  color: $merkury-green !important;
}

.geeni-migration-model-progress {
  position: relative;
  color: #fff !important;
}

.geeni-loading-progress {
  top: 50%;
  left: 50%;
  margin-top: 15px;
  color: $merkury-green !important;
}

.geeni-small-loading-progress {
  color: $merkury-green !important;
  margin-left: 10px;
}

.geeni-device-control {
  margin-left: 130px;
  margin-top: 15px;
  font-weight: 600;
  margin: auto;
}

.geeni-link-text {
  text-decoration: none;
  color: #666666;
  font-weight: bold;
}

.geeni-link-text:hover {
  text-decoration: none;
  color: $merkury-green;
}

.geeni-btn-card {
  width: 256px;
  height: 42px;
  font-weight: bolder !important;
  color: $white !important;
}

.geeni-btn-card-selected {
  background-color: transparent !important;
  color: $merkury-green !important;
}

.geeni-card-icon {
  height: 30px;
  margin-bottom: 2px;
}

.geeni-font {
  p,
  div,
  li,
  span,
  button {
    font-family: 'Avenir Next Rounded' !important;
  }
}

.geeni-bg,
.geeni-bg button,
geeni-font {
  font-family: 'Avenir Next Rounded' !important;
}

#nightowl-exit-btn,
#nightowl-cancel-btn {
  color: var(--on-surface) !important;
}

#nightowl-next-btn {
  color: var(--on-surface) !important;
  &.Mui-disabled {
    opacity: 0.5;
  }
}

.geeni-device-card-selected {
  border: $merkury-green 2px solid !important;
}

.geeni-cancel-plan-page {
  max-width: 600px;
  margin-top: 80px;
  margin-left: 174px;
}

.reset-password {
  .form-control {
    border-color: #ced4da;
    border-style: solid;
    border-width: 0 0 1px 0;
  }

  .btn-primary-2 {
    background-color: theme-color('primary') !important;
    color: white !important;
    border-color: transparent;
  }

  .btn-primary-2.disabled {
    color: #5c687c;
    background-color: #ededed;
    border-color: transparent;
  }

  .input-container {
    position: relative;

    img {
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
}

.message-banner {
  align-items: center;
  background: red;
  display: flex;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  img {
    height: 20px;
    width: 20px;
  }

  span {
    color: white;
    flex: 1;
    line-height: 20px;
    padding-right: 12px;
    text-align: center;
  }
}

.geeni-payment-details-header {
  background: $merkury-green !important;
  color: $white !important;
}

.geeni-manage-devices-header-color {
  background: $merkury-green !important;
  color: $white !important;
}

.geeni-order-summary-header {
  background: $merkury-green !important;
  color: $white !important;
}

.geeni-explanation {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-left: 33px;
  margin-right: 25px;
}
@media screen and (max-width: 1200px) {
  .nightowl-plan-device-text {
    margin-left: 124px;
  }

  .nightowl-plan-device-card {
    margin-left: 124px;
    margin-right: 124px;
  }

  .nightowl-plan-action-control {
    margin-left: 100px;
    margin-right: 100px;
  }

  .geeni-header-logo {
    margin-left: 100px !important;
  }

  .geeni-cancel-plan-page {
    margin-left: 124px;
    margin-top: 60px;
  }
}

@media screen and (max-width: 992px) {
  .nightowl-plan-device-text {
    margin-left: 34px;
  }

  .nightowl-plan-device-card {
    margin-left: 34px;
    margin-right: 34px;
  }

  .nightowl-plan-action-control {
    margin-left: 34px;
    margin-right: 34px;
  }

  .geeni-header-logo {
    margin-left: 0 !important;
  }

  .geeni-header-data {
    margin-right: 0 !important;
  }

  .geeni-plan-top-bar-text {
    margin-left: 10px;
  }

  .geeni-device-three-dots {
    margin-right: 10px;
  }

  .geeni-btn-action {
    max-width: 200px !important;
  }

  .geeni-cancel-plan-page {
    margin-left: 34px;
    margin-right: 34px;
    height: 600px;
    margin-top: 40px;
  }
}

//mobile view
@media screen and (max-width: 600px) {
  .nightowl-forgot-password-content {
    margin: 123px 0 0 0;
    padding: 32px;
  }

  .nightowl-text-primary {
    margin-top: 15px;
  }

  .nightowl-plan-device-text {
    margin-left: 14px;
  }

  .nightowl-login-paper {
    margin-top: 15px !important;
  }

  .nightowl-login-logo {
    max-width: 215px;
    margin-top: 20vh;
  }

  .nightowl-plan-device-card {
    margin-left: 14px;
    margin-right: 14px;
  }

  .nightowl-plan-action-control {
    margin-left: 10px;
    margin-right: 10px;
  }

  .nightowl-plan-action-control-buttons {
    flex-direction: column;
  }

  .geeni-header-logo {
    margin-left: 0 !important;
    height: 50px;
  }

  .geeni-header-data {
    margin-right: 0 !important;
  }

  .geeni-plan-top-bar-text {
    margin-left: 0px;
  }

  .geeni-device-three-dots {
    margin-right: 0px;
  }

  .geeni-tab-panel-control {
    padding: 0px !important;
  }
  .geeni-plan-card {
    padding: 0px !important;
  }

  .geeni-plan-card-title {
    padding: 0px !important;
  }

  .geeni-plan-card-title-selected {
    padding: 0px !important;
  }
  .MuiCardActions-root {
    padding: 0 !important;
  }

  .MuiCardContent-root {
    padding: 4px !important;
  }

  .geeni-btn-action {
    max-width: 150px !important;
    padding-bottom: 5px !important;
    margin-right: 2px !important;
    margin-top: 0 !important;
  }

  .geeni-btn-action-disabled {
    max-width: 150px !important;
    padding-bottom: 5px !important;
    margin-top: 0 !important;
  }

  .geeni-btn-text {
    margin: 0 !important;
  }

  .geeni-cancel-plan-page {
    margin: 14px;
    height: 600px;
  }
}
