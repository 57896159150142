.order-summary-header-text {
  margin-left: 184px;
}

.order-summary-body {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.your-plan-control {
  display: flex;
  flex-direction: column;
}

.your-plan-table-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summary-order-footer {
  height: 94px !important;
  bottom: 60px;
  position: fixed;
}

.summary-order-footer-action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: inherit;
}

//mobile

@media screen and (max-width: 1200px) {
  .order-summary-header-text {
    margin-left: 134px;
  }
}

@media screen and (max-width: 992px) {
  .order-summary-header-text {
    margin-left: 34px;
  }
  .order-summary-body {
    flex-direction: column;
  }

  .your-plan-control {
    margin-top: 25px;
  }
}

//mobile view
@media screen and (max-width: 600px) {
  .order-summary-header-text {
    margin-left: 10px;
  }
  .order-summary-body {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .your-plan-control {
    margin-top: 25px;
    padding-bottom: 150px;
  }

  .summary-order-footer {
    bottom: 50px;
  }
}
