/*
 * Typography
 * All Round is a sans serif typeface available in five weights, all of which can be used. 
 * Clean, legible, and modern, it reinforces our status as experts in quality products.
 * You may use our font in headlines, product names, and titles.
 * Do not use this for subheaders and denser body copy.
 */
@font-face {
  font-family: 'All Round Gothic';
  src: url('fonts/AllRoundGothic-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'All Round Gothic';
  src: url('fonts/AllRoundGothic-Demi.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'All Round Gothic';
  src: url('fonts/AllRoundGothic-Bold.otf') format('opentype');
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: 'All Round Gothic';
  src: url('fonts/AllRoundGothic-XLig.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

/*
 * Secondary Typeface
 * For longer subheaders, please use the Avenir Next Rounded font. 
 * Avenir Next Rounded is a legible font with a friendly feel without being to distracting. 
 */
@font-face {
  font-family: 'Avenir Next Rounded';
  src: url('fonts/AvenirNextRoundedStd-Reg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Rounded';
  src: url('fonts/AvenirNextRoundedStd-Med.woff2') format('woff2');
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Rounded';
  src: url('fonts/AvenirNextRoundedStd-Demi.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Rounded';
  src: url('fonts/AvenirNextRoundedStd-BoldIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next Rounded';
  src: url('fonts/AvenirNextRoundedStd-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next Rounded';
  src: url('fonts/AvenirNextRoundedStd-Med.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
