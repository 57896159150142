.checkout-action-footer {
  height: 94px !important;
  bottom: 60px;
  position: fixed;
}

.checkout-action-footer-action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: inherit;
}
