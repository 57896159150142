@font-face {
  font-family: 'Proxima Nova Black';
  src: url('fonts/ProximaNova-Black.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/ProximaNova-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/ProximaNova-BoldIt.otf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/ProximaNova-Extrabold.otf');
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/ProximaNova-Light.otf');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/ProximaNova-LightItalic.otf');
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/ProximaNova-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/ProximaNova-RegularItalic.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('fonts/ProximaNova-Semibold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('fonts/ProximaNova-SemiboldItalic.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova Cond Light';
  src: url('fonts/ProximaNovaCond-Light.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova Cond Light';
  src: url('fonts/ProximaNovaCond-LightIt.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova Cond';
  src: url('fonts/ProximaNovaCond-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova Cond';
  src: url('fonts/ProximaNovaCond-RegularIt.otf');
  font-weight: bold;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova Cond';
  src: url('fonts/ProximaNovaCond-Semibold.otf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova Cond';
  src: url('fonts/ProximaNovaCond-SemiboldIt.otf');
  font-weight: 600;
  font-style: italic;
}
