@import 'custom';

$pepper-dark-gray: #626262;

.pepper-screen-bg {
  background-color: $green;
  height: 100vh;
  position: absolute;
  max-width: 100vw !important;
}

.pepper-bg {
  background-color: $white !important;
  padding-bottom: 25px;
}

.pepper-error-status-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 500px;
  min-width: 200px;
  min-height: 200px;
}

.pepper-empty-layout {
  background-color: $green !important;
  width: 100%;
  height: 100%;
  max-width: 100vw !important;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.pepper-empty-layout.white {
  background-color: $white !important;
}

.pepper-migration-model {
  background-color: $green !important;
  color: #fff !important;
}

.pepper-login-logo {
  padding: 60px 0 30px 0;
}

.pepper-header-logo {
  flex-grow: 1;
  height: 70px;
  padding: 10px;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 150px;
}

.pepper-header-data {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 150px;
}

.pepper-promo-code-error {
  width: 244px;
  color: $red;
}

.pepper-promo-code-valid {
  width: 244px;
  color: $green;
}

.pepper-btn {
  color: white !important;
  background-color: $green !important;
  border-radius: 30px !important;
  margin: 10px !important;
}

.pepper-btn-action {
  color: white !important;
  background-color: $green !important;
  border-radius: 30px !important;
  margin: 10px !important;
}

.pepper-btn-reset-password {
  color: white !important;
  background-color: $green !important;
  border-radius: 30px !important;
}

.pepper-btn-reset-password-disabled {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-radius: 30px !important;
}

.pepper-btn-action-error {
  color: white !important;
  background-color: $red !important;
  border-radius: 30px !important;
  margin: 10px !important;
}

.pepper-btn-action-disabled {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-radius: 30px !important;
  margin: 10px !important;
}

.pepper-btn-text {
  color: $green !important;
  background-color: transparent !important;
  font-weight: bold !important;
}

.pepper-btn-text-disabled {
  color: #626262 !important;
  cursor: default;
  opacity: 0.5;
  font-weight: bold !important;
}

.pepper-primary {
  color: $green !important;
  background-color: transparent !important;
  font-weight: bold !important;
}

.pepper-btn-text:hover {
  text-decoration: underline;
}

//TextField
.pepper-text-field .Mui-focused fieldset {
  border-color: $green !important;
}

.pepper-text-field label.Mui-focused {
  color: $green !important;
}

.pepper-text-field .MuiInput-underline:after {
  color: $green !important;
  border-color: $green !important;
}

.pepper-text-primary {
  color: $green !important;
}

.pepper-checkbox {
  color: $green !important;
}

.pepper-login-button {
  background-color: $green !important;
  border-radius: 30px !important;
  padding: 10px !important;
}

.pepper-login-button-disabled {
  border-radius: 30px !important;
  padding: 10px !important;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.pepper-logo-app-bar {
  background: -webkit-linear-gradient($gradientTop, $gradientBottom) !important; /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient($gradientTop, $gradientBottom) !important; /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient($gradientTop, $gradientBottom) !important; /* For Firefox 3.6 to 15 */
  background: linear-gradient($gradientTop, $gradientBottom) !important; /* Standard syntax */
}

.pepper-app-bar {
  background-color: $green !important;
}

.pepper-app-bar-secondary {
  background-color: $gray-800 !important;
  border-radius: 0px !important;
  color: white;
}

.pepper-log-out-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: bolder;
}

.pepper-log-out {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: flex-end;
}

.pepper-footer {
  background-color: $gray-200;
  color: $gray-700;
  font-size: 0.85em;
  display: flex;
  align-items: center;
}

.pepper-tabs {
  color: white !important;
}

.pepper-tab:hover {
  background-color: $green-hover;
}

.pepper-tabs-secondary {
  color: white !important;
}

.pepper-tab-secondary:hover {
  background-color: $gray-900;
}

.pepper-sub-app-bar {
  background-color: $white !important;
  color: $black !important;
  border-bottom: 1px solid rgb(222, 226, 230);
  border-top: 1px solid rgb(222, 226, 230);
}

.pepper-text-common {
  color: $pepper-dark-gray;
  font-weight: 500 !important;
}

.pepper-text-b {
  font-weight: bold !important;
}

.pepper-error-text {
  color: $red;
}

.pepper-form-label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bolder;
  margin-left: 4px;
  color: #0000008a;
}

.pepper-form-label-error {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bolder;
  margin-left: 4px;
  color: $red;
}

.pepper-button-progress {
  position: relative;
  margin: 15px;
  color: $green !important;
}

.pepper-migration-model-progress {
  position: relative;

  color: #fff !important;
}

.pepper-loading-progress {
  top: 50%;
  left: 50%;
  margin-top: 15px;
  color: $green !important;
}

.pepper-small-loading-progress {
  color: $green !important;
  margin-left: 10px;
}

.pepper-subscriptions-control {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  height: 100%;
}

.pepper-subscriptions-control-swap-order {
  display: flex;
  flex-direction: column-reverse !important;
  width: 100%;
  height: 100%;
}

.pepper-device-control {
  margin-left: 130px;
  margin-top: 15px;
  font-weight: 600;
  margin: auto;
}

.pepper-device-image {
  height: 65px;
}

.pepper-plan-top-bar-text {
  margin-left: 150px;
}

.pepper-plan-device-card {
  display: flex;
  height: 100px;
  align-items: center;
  margin-left: 174px;
  margin-right: 174px;
}

.pepper-device-three-dots {
  margin-right: 150px;
}

.pepper-plan-device-text {
  margin-top: 25px;
  margin-bottom: 12px;
  margin-left: 174px;
  font-weight: bold;
}

.pepper-footer-text {
  margin-left: 150px;
  text-decoration: none;
  font-weight: bold;
  color: $gray-700 !important;
}

.pepper-link-text {
  text-decoration: none;
  color: #666666;
  font-weight: bold;
}

.pepper-link-text:hover {
  text-decoration: none;
  color: $green;
}

.pepper-plan-card {
  max-width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: transparent 3px solid !important;
  margin: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pepper-plan-card-selected {
  border: $green 3px solid !important;
}

.pepper-plan-card-title {
  color: black;
  max-width: 275px;
}

.pepper-plan-card-title-selected {
  font-weight: bolder !important;
}

.pepper-btn-card {
  width: 256px;
  height: 42px;
  font-weight: bolder !important;
}

.pepper-btn-card-selected {
  background-color: transparent !important;
  color: $green !important;
}

.pepper-card-icon {
  height: 30px;
  margin-bottom: 2px;
}

.pepper-plans-control {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pepper-plan-action-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  margin-left: 174px;
  margin-right: 174px;
}

.pepper-plan-action-control-buttons {
  display: flex;
  flex-direction: row;
}

.pepper-device-card-selected {
  border: $green 2px solid !important;
}

.pepper-cancel-plan-page {
  max-width: 600px;
  margin-top: 80px;
  margin-left: 174px;
}

.reset-password {
  .form-control {
    border-color: #ced4da;
    border-style: solid;
    border-width: 0 0 1px 0;
  }

  .btn-primary-2 {
    background-color: theme-color('primary') !important;
    color: white !important;
    border-color: transparent;
  }

  .btn-primary-2.disabled {
    color: #5c687c;
    background-color: #ededed;
    border-color: transparent;
  }

  .input-container {
    position: relative;

    img {
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
}

.message-banner {
  align-items: center;
  background: red;
  display: flex;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  img {
    height: 20px;
    width: 20px;
  }

  span {
    color: white;
    flex: 1;
    line-height: 20px;
    padding-right: 12px;
    text-align: center;
  }
}

.pepper-payment-details-header {
  background: $white !important;
  color: $black !important;
}

.pepper-manage-devices-header-color {
  background: $white !important;
  color: $black !important;
}

.pepper-order-summary-header {
  background: $white !important;
  color: $black !important;
}

@media screen and (max-width: 1200px) {
  .pepper-header-logo {
    margin-left: 100px !important;
  }

  .pepper-header-data {
    margin-right: 100px !important;
  }

  .pepper-plan-top-bar-text {
    margin-left: 100px;
  }

  .pepper-plan-device-card {
    margin-left: 124px;
    margin-right: 124px;
  }

  .pepper-device-three-dots {
    margin-right: 100px;
  }

  .pepper-plan-device-text {
    margin-left: 124px;
  }

  .pepper-footer-text {
    margin-left: 100px;
  }

  .pepper-plan-action-control {
    margin-left: 100px;
    margin-right: 100px;
  }

  .pepper-cancel-plan-page {
    margin-left: 124px;
    margin-top: 60px;
  }
}

@media screen and (max-width: 992px) {
  .pepper-header-logo {
    margin-left: 0 !important;
  }

  .pepper-header-data {
    margin-right: 0 !important;
  }

  .pepper-plan-top-bar-text {
    margin-left: 10px;
  }

  .pepper-plan-device-card {
    margin-left: 34px;
    margin-right: 34px;
  }

  .pepper-device-three-dots {
    margin-right: 10px;
  }

  .pepper-plan-device-text {
    margin-left: 34px;
  }

  .pepper-footer-text {
    margin-left: 10px;
  }

  .pepper-plan-action-control {
    margin-left: 34px;
    margin-right: 34px;
  }

  .pepper-btn-action {
    max-width: 200px !important;
  }

  .pepper-cancel-plan-page {
    margin-left: 34px;
    margin-right: 34px;
    height: 600px;
    margin-top: 40px;
  }
}

//mobile view
@media screen and (max-width: 600px) {
  .pepper-login-paper {
    margin-top: 15px !important;
  }

  .pepper-text-primary {
    margin-top: 15px;
  }

  .pepper-header-logo {
    margin-left: 0 !important;
    height: 50px;
  }

  .pepper-header-data {
    margin-right: 0 !important;
  }

  .pepper-plan-top-bar-text {
    margin-left: 0px;
  }

  .pepper-plan-device-card {
    margin-left: 14px;
    margin-right: 14px;
  }

  .pepper-device-three-dots {
    margin-right: 0px;
  }

  .pepper-plan-device-text {
    margin-left: 14px;
  }

  .pepper-tab-panel-control {
    padding: 0px !important;
  }
  .pepper-plan-card {
    padding: 0px !important;
  }

  .pepper-plan-card-title {
    padding: 0px !important;
  }

  .pepper-plan-card-title-selected {
    padding: 0px !important;
  }
  .MuiCardActions-root {
    padding: 0 !important;
  }

  .MuiCardContent-root {
    padding: 4px !important;
  }

  .pepper-plan-action-control-buttons {
    flex-direction: column;
  }

  .pepper-plan-action-control {
    margin-left: 10px;
    margin-right: 10px;
  }

  .pepper-btn-action {
    max-width: 150px !important;
    padding-bottom: 5px !important;
    margin-right: 2px !important;
    margin-top: 0 !important;
  }

  .pepper-btn-action-disabled {
    max-width: 150px !important;
    padding-bottom: 5px !important;
    // margin-right: 2px !important;
    margin-top: 0 !important;
  }

  .pepper-btn-text {
    margin: 0 !important;
  }

  .pepper-cancel-plan-page {
    margin: 14px;
    height: 600px;
  }
}
