// overriding modal styles from MUI
.nightowl-body {
  .MuiPaper-root.MuiDialog-paper {
    background-color: var(--surface-container);
    color: var(--on-surface);

    .MuiTypography-colorTextSecondary {
      color: var(--on-surface);
    }
  }
}