
// created to combat MUI styles
.nightowl-plan-action-control-wrapper {
  background-color: var(--surface)!important;
  color: var(--on-surface)!important;
}

.nightowl-plan-action-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  margin-left: 174px;
  margin-right: 174px;
}

.nightowl-plan-action-control-buttons {
  display: flex;
  flex-direction: row;
}

.nightowl-plans-control-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.nightowl-plans-control {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.nightowl-plan-card {
  max-width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: transparent 3px solid !important;
  margin: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  &.MuiPaper-root {
    background: var(--surface-dim);
    color: var(--on-surface);
  }
  
  & .card-divider {
    border-color: var(--neutral-7);
    background: var(--neutral-7);
  }

  // plan features list
  .MuiTypography-colorTextSecondary {
    color: var(--on-surface);
  }
}

.nightowl-plan-card-selected {
  border: var(--primary) 3px solid !important;
}

.nightowl-plan-card-title {
  max-width: 275px;
}

.nightowl-plan-card-title-selected {
  font-weight: bolder !important;
}

.nightowl-plan-device-card {
  &.MuiPaper-root {
    background: var(--surface) !important;
    color: var(--on-surface) !important;
  }
  display: flex;
  height: 100px;
  align-items: center;
  margin-left: 174px;
  margin-right: 174px;
}
