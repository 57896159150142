// Background of Tabs inside authenticated views
.nightowl-app-bar {
  background-color: var(--surface) !important;
}

// Monthly / yearly tabs
.nightowl-app-bar-secondary {
  border-radius: 0px !important;
}

// Top header with logo and sign-out links
.nightowl-logo-app-bar {
  background: var(--surface) !important;
  color: var(--on-surface) !important;
  h5 {
    font-weight: bold !important;
  }

  .nightowl-header-data {
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 150px;
  }
}

.nightowl-device-three-dots {
  margin-right: 150px;
}

.nightowl-log-out {
  display: flex;
  flex-direction: row;
  margin: 15px 10px 0 0;
  align-items: flex-end;
}

.nightowl-header-log-in-link {
  color: var(--on-surface);
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.nightowl-log-out-link {
  color: var(--on-surface);
  text-decoration: none;
  font-weight: bold;
}

// Header with plan details below tabs, i.e. "No Plan(yearly)" and vertical dots menu
.nightowl-sub-app-bar {
  background-color: var(--surface) !important;
  color: var(--on-surface) !important;
  border-bottom: 1px solid var(--outline-variant) !important;
  border-top: 1px solid var(--outline);

  & .nightowl-plan-top-bar-text {
    margin-left: 150px;
  }
}

@media screen and (max-width: 1200px) {
  .nightowl-device-three-dots {
    margin-right: 100px;
  }
  .nightowl-plan-top-bar-text {
    margin-left: 100px;
  }
}
