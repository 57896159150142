.nightowl-footer {
  background-color: var(--surface-dim);
  color: var(--on-surface);
  font-size: 0.85em;
  display: flex;
  align-items: center;
}

.nightowl-footer-text {
  margin-left: 150px;
  text-decoration: none;
  font-weight: bold;
  color: var(--on-surface) !important;
}

.nightowl-link-text {
  text-decoration: none;
  color: var(--on-surface);
}

.nightowl-link-text:hover {
  text-decoration: none;
  color: var(--primary);
}

@media screen and (max-width: 992px) {
  .nightowl-footer-text {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .nightowl-footer-text {
    margin-left: 100px;
  }
}
