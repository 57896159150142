//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900,
) !default;

$black: #000000;
$darkGrey: #75726f;
$mediumGrey: #a3a19f;
$lightGrey: #f0efef;
$white: #ffffff;

$darkBlue: #023f88 !default;
$blue: #00b3f0 !default;
$lightBlue: #72d5ff !default;
$indigo: #888bd8 !default;
$purple: #888bd8 !default;
$pink: #e83e8c !default;
$red: #f03c00 !default;
$orange: #fbb77f !default;
$yellow: #ffc42e !default;
$green: #66a723 !default;
$teal: #20c997 !default;
$cyan: $blue !default;
$green-hover: #3d6900 !default;

$gradientTop: #9ed139;
$gradientBottom: $green;

$colors: (
  blue: $blue,
  lightBlue: $lightBlue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
) !default;

$theme-colors: (
  primary: $blue,
  secondary: $darkBlue,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
) !default;
