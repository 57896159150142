
.nightowl-btn,
.nightowl-btn-action {
  color: var(--on-primary) !important;
  background-color: var(--primary) !important;
  border-radius: 30px !important;
  margin: 10px !important;
  font-weight: bold !important;
}

.nightowl-btn-card {
  width: 256px;
  height: 42px;
  font-weight: bolder !important;
}

.nightowl-btn-reset-password { // @TODO review
  color: var(--on-primary) !important;
  background-color: var(--primary) !important;
  border-radius: 30px !important;
}

.nightowl-btn-reset-password-disabled { // @TODO review
  color: var(--on-primary) !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-radius: 30px !important;
}

.nightowl-btn-action-error {
  color: var(--on-primary) !important;
  background-color: $red !important; // @TODO review
  border-radius: 30px !important;
  margin: 10px !important;
}

.nightowl-btn-action-disabled {
  color: var(--on-primary) !important;
  background-color: rgba(0, 0, 0, 0.4) !important; // @TODO review
  border-radius: 30px !important;
  margin: 10px !important;
}

.nightowl-btn-text {  // @TODO review
  color: var(--primary) !important;
  background-color: transparent !important;
  font-weight: bold !important;
}

.nightowl-btn-text-disabled {  // @TODO review
  color: #626262 !important;
  cursor: default;
  opacity: 0.5;
  font-weight: bold !important;
}