@import 'custom';
@import 'components';

:root {
  // Base color palette from Figma
  // Palette is consumed by the semantic tokens below
  --primary-1: #00B9FF;
  --secondary-1: #92ABC2;
  --neutral-1: #FFFFFF;
  --neutral-2: #EDEDED;
  --neutral-3: #9396A2;
  --neutral-4: #787B87;
  --neutral-5: #6C6D75;
  --neutral-6: #414147;
  --neutral-7: #2F2F2F;
  --neutral-8: #101013;
  --neutral-9: #000000;
  --accent-1: #85DEFF;
  --accent-2: #00B9FF;
  --accent-3: #337BDC;
  --accent-4: #F0D53C;
  --accent-5: #F7A801;
  --accent-6: #F67600;
  --accent-7: #D1A3FF;
  --accent-8: #B778DE;
  --accent-9: #A353F2;
  --accent-10: #62EBB1;
  --accent-11: #3CD193;
  --accent-12: #17AA87;
  --accent-13: #FFE288;
  --accent-14: #A0C7DD;
  --error: #FC5656;
  --error-low: #5C0202;
  --success: #62C56C;
  --success-low: #024F17;
  --emphasis: #FC5656;
  --emphasis-low: #5C0202;
  
  // Semantic tokens from Figma
  // These tokens are the variables consumed and implemented by the UI
  --primary: var(--primary-1);
  --on-primary: var(--neutral-8);
  --secondary: var(--secondary-1);
  --on-secondary: var(--neutral-8);
  --surface: var(--neutral-8);
  --surface-dim: var(--neutral-9);
  --surface-container: var(--neutral-7);
  --on-surface: var(--neutral-2);
  --on-surface-variant: var(--neutral-3);
  --outline: var(--neutral-5);
  --outline-variant: var(--neutral-7);
}