//common
#alert-dialog-title {
  padding-top: 25px;
  padding-bottom: 10px;
  text-align: center;
}

#data-loss-dialog-title {
  padding-top: 25px;
  padding-bottom: 10px;
  text-align: left;
}

#subs-success-modal-title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#alert-dialog-description {
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

#data-loss-dialog-description {
  text-align: left;
  color: black;
}

#subs-success-modal-description {
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
}

#alert-dialog-actions {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  padding: 15px;
}

#data-loss-dialog-actions {
  display: flex;
  flex-direction: column;
  // width: 100%;
}

#delete-account-dialog-actions {
  display: flex;
  flex-direction: row;
}

.geeni-confirm-modal-icon {
  //   padding: 15px;
  height: 80px;
  margin: 15px;
}

.notion-confirm-modal-icon {
  //   padding: 15px;
  height: 80px;
  margin: 15px;
}

.otis-confirm-modal-icon {
  //   padding: 15px;
  height: 80px;
  margin: 15px;
}

.pepper-confirm-modal-icon {
  //   padding: 15px;
  height: 80px;
  margin: 15px;
}

.visi-confirm-modal-icon {
  //   padding: 15px;
  height: 80px;
  margin: 15px;
}

.momentum-confirm-modal-icon {
  //   padding: 15px;
  height: 80px;
  margin: 15px;
}

//mobile view
@media screen and (max-width: 600px) {
  #subs-success-modal-description {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    display: block;
    align-items: center;
    width: 100%;
  }
  #delete-account-dialog-actions {
    display: flex;
    flex-direction: column;
  }
}
