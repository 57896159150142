.nightowl-tabs {
  color: var(--on-surface) !important;

  & .MuiTabs-indicator {
    background-color: var(--primary) !important;
  }
}

.nightowl-tab {
  &.MuiTab-textColorInherit {
    opacity: 1;
  }

  &.Mui-selected {
    color: var(--primary)
  }
}

.nightowl-tabs-secondary {
  background-color: var(--surface) !important;
  color: var(--on-surface) !important;
  & .MuiTabs-indicator {
    background-color: var(--primary) !important;
  }
}
