html {
  width: 100%;
  height: 100%;
  overflow: -moz-scrollbars-none;
}

body {
  margin: 0;
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;

  width: 100%;
  height: 100%;
  line-height: 1 !important;
  font-weight: normal;
  font-size: 0.875rem;
}

#app {
  height: 100%;
  min-width: 100%;
}

*,
:after,
:before {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
