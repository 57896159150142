@import 'custom';

.momentum-screen-bg {
  background-color: $blue;
  height: 100vh;
  position: absolute;
  max-width: 100vw !important;
}

.momentum-bg {
  background-color: $white;
  padding-bottom: 25px;
}

.momentum-empty-layout {
  background-color: $blue !important;
  width: 100%;
  height: 100%;
  max-width: 100vw !important;
  height: 100vh;
}

.momentum-empty-layout.white {
  background-color: $white !important;
}

.momentum-ribbon {
  background-color: $orange !important;
  color: white !important;
  top: 15px;
}

.momentum-form-label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bolder;
  margin-left: 4px;
  color: #0000008a;
}

.momentum-form-label-error {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bolder;
  margin-left: 4px;
  color: $red;
}

.momentum-login-logo {
  padding: 30px;
  height: 180px;
}

.momentum-text-primary {
  color: $blue !important;
}

.momentum-header-logo {
  flex-grow: 1;
  height: 70px;
  padding: 10px;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 150px;
}

.momentum-header-data {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 150px;
}

.momentum-promo-code-error {
  width: 244px;
  color: $red;
}

.momentum-promo-code-valid {
  width: 244px;
  color: $blue;
}

.momentum-btn {
  color: white !important;
  background-color: $blue !important;
  border-radius: 0px !important;
  margin: 10px !important;
}

.momentum-btn-reset-password {
  color: white !important;
  background-color: $blue !important;
  border-radius: 0 !important;
}

.momentum-btn-reset-password-disabled {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.momentum-btn-action {
  color: white !important;
  background-color: $blue !important;
  border-radius: 0px !important;
  margin: 10px !important;
}

.momentum-btn-action-error {
  color: white !important;
  background-color: $red !important;
  border-radius: 30px !important;
  margin: 10px !important;
}

.momentum-btn-action-disabled {
  color: white !important;
  background-color: rgba(77, 77, 77, 0.4) !important;
  border-radius: 0 !important;
  margin: 10px !important;
}

.momentum-primary {
  color: $blue !important;
  background-color: transparent !important;
  font-weight: bold !important;
}

.momentum-btn-text {
  color: $orange !important;
  background-color: transparent !important;
  font-weight: bold !important;
}

.momentum-btn-text-disabled {
  color: #626262 !important;
  cursor: default;
  opacity: 0.5;
  font-weight: bold !important;
}

.momentum-btn-text:hover {
  text-decoration: underline !important;
}

//TextField
.momentum-text-field .Mui-focused fieldset {
  border-color: $blue !important;
}

.momentum-text-field label.Mui-focused {
  color: $blue !important;
}

.momentum-text-field .MuiInput-underline:after {
  color: $blue !important;
  border-color: $blue !important;
}

.momentum-checkbox {
  color: $blue !important;
}

.momentum-login-button {
  background-color: $blue !important;
  border-radius: 0px !important;
  padding: 10px !important;
}

.momentum-login-button-disabled {
  border-radius: 0px !important;
  padding: 10px !important;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.momentum-logo-app-bar {
  background: $gradientTop !important;
  background: -webkit-linear-gradient($gradientTop, $gradientBottom) !important;
  background: -o-linear-gradient($gradientTop, $gradientBottom) !important;
  background: -moz-linear-gradient($gradientTop, $gradientBottom) !important;
  background: linear-gradient($gradientTop, $gradientBottom) !important;
}

.momentum-app-bar {
  background-color: $blue !important;
}

.momentum-app-bar-secondary {
  background-color: $gray-800 !important;
  border-radius: 0px !important;
  color: white;
}

.momentum-log-out-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: bolder;
}

.momentum-log-out {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: flex-end;
}

.momentum-footer {
  background-color: $gray-200;
  color: $gray-700;
  font-size: 0.85em;
  display: flex;
  align-items: center;
}

.momentum-tabs {
  color: white;
}

.momentum-tab:hover {
  background-color: $blue-hover;
}

.momentum-tabs-secondary {
  color: white;
}

.momentum-tab-secondary:hover {
  background-color: $gray-900;
}

.momentum-sub-app-bar {
  background-color: $white !important;
  color: $black !important;
  border-bottom: 1px solid rgb(222, 226, 230);
  border-top: 1px solid rgb(222, 226, 230);
}

.momentum-text-common {
  color: $gray-700;
}

.momentum-error-status-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 500px;
  min-width: 200px;
  min-height: 200px;
}

.momentum-text-colored {
  color: $orange !important;
}

.momentum-text-b {
  font-weight: bold !important;
}

.momentum-error-text {
  color: $red;
}

.momentum-button-progress {
  position: relative;
  margin: 15px;
  color: $blue !important;
}

.momentum-migration-model-progress {
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: 15px;
  margin-left: -12px;
  color: #fff !important;
}

.momentum-loading-progress {
  top: 50%;
  left: 50%;
  margin-top: 15px;
  color: $blue !important;
}

.momentum-small-loading-progress {
  color: $blue !important;
  margin-left: 10px;
}

.momentum-subscriptions-control {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.momentum-subscriptions-control-swap-order {
  display: flex;
  flex-direction: column-reverse !important;
  width: 100%;
  height: 100%;
}

.momentum-device-control {
  margin-left: 130px;
  margin-top: 15px;
  font-weight: 600;
  margin: auto;
}

.momentum-device-image {
  height: 45px;
}

.momentum-plan-top-bar-text {
  margin-left: 150px;
}

.momentum-plan-device-card {
  display: flex;
  height: 100px;
  align-items: center;
  margin-left: 174px;
  margin-right: 174px;
}

.momentum-device-three-dots {
  margin-right: 150px;
}

.momentum-plan-device-text {
  margin-top: 15px;
  margin-bottom: 6px;
  margin-left: 174px;
  font-weight: bold;
}

.momentum-footer-text {
  margin-left: 150px;
  text-decoration: none;
  font-weight: bold;
  color: $gray-700;
}

.momentum-link-text {
  text-decoration: none;
  color: #666666;
  font-weight: bold;
}

.momentum-link-text:hover {
  text-decoration: none;
  color: $blue;
}

.momentum-plan-card {
  max-width: 300px;
  padding-bottom: 15px;
  margin: 25px;

  border: transparent 3px solid !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.momentum-plan-card-selected {
  border: $blue 3px solid !important;
}

.momentum-plan-card-title {
  color: black;
  max-width: 275px;
}

.momentum-plan-card-title-selected {
  font-weight: bolder !important;
}

.momentum-btn-card {
  width: 256px;
  height: 42px;
  font-weight: bolder !important;
}

.momentum-btn-card-selected {
  background-color: transparent !important;
  color: $blue !important;
  opacity: 0.8;
}

.momentum-card-icon {
  height: 30px;
  margin-bottom: 2px;
}

.momentum-plans-control {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.momentum-plan-action-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  margin-left: 174px;
  margin-right: 174px;
}

.momentum-plan-action-control-buttons {
  display: flex;
  flex-direction: row;
}

.momentum-device-card-selected {
  border: $blue 2px solid !important;
}

.momentum-payment-details-header {
  background: $white !important;
  color: $black !important;
}

.momentum-manage-devices-header-color {
  background: $white !important;
  color: $black !important;
}

.momentum-order-summary-header {
  background: $white !important;
  color: $black !important;
}

@media screen and (max-width: 1200px) {
  .momentum-header-logo {
    margin-left: 100px !important;
  }

  .momentum-header-data {
    margin-right: 100px !important;
  }

  .momentum-plan-top-bar-text {
    margin-left: 100px;
  }

  .momentum-plan-device-card {
    margin-left: 124px;
    margin-right: 124px;
  }

  .momentum-device-three-dots {
    margin-right: 100px;
  }

  .momentum-plan-device-text {
    margin-left: 124px;
  }

  .momentum-footer-text {
    margin-left: 100px;
  }

  .momentum-plan-action-control {
    margin-left: 34px;
    margin-right: 34px;
  }

  .momentum-btn-action {
    max-width: 200px !important;
  }
}

@media screen and (max-width: 992px) {
  .momentum-header-logo {
    margin-left: 0 !important;
  }

  .momentum-header-data {
    margin-right: 0 !important;
  }

  .momentum-plan-top-bar-text {
    margin-left: 10px;
  }

  .momentum-plan-device-card {
    margin-left: 34px;
    margin-right: 34px;
  }

  .momentum-device-three-dots {
    margin-right: 10px;
  }

  .momentum-plan-device-text {
    margin-left: 34px;
  }

  .momentum-footer-text {
    margin-left: 10px;
  }

  .momentum-plan-action-control {
    margin-left: 100px;
    margin-right: 100px;
  }
}

//mobile view
@media screen and (max-width: 600px) {
  .momentum-login-paper {
    margin-top: 15px !important;
  }

  .momentum-text-primary {
    margin-top: 15px;
  }

  .momentum-header-logo {
    margin-left: 0 !important;
    height: 50px;
  }

  .momentum-header-data {
    margin-right: 0 !important;
  }

  .momentum-plan-top-bar-text {
    margin-left: 0px;
  }

  .momentum-plan-device-card {
    margin-left: 14px;
    margin-right: 14px;
  }

  .momentum-device-three-dots {
    margin-right: 0px;
  }

  .momentum-plan-device-text {
    margin-left: 14px;
  }

  .momentum-tab-panel-control {
    padding: 0px !important;
  }

  .momentum-btn-action-disabled {
    max-width: 150px !important;
    padding-bottom: 5px !important;
    // margin-right: 2px !important;
    margin-top: 0 !important;
  }

  .momentum-plan-action-control-buttons {
    flex-direction: column;
  }

  .momentum-plan-action-control {
    margin-left: 10px;
    margin-right: 10px;
  }

  .momentum-btn-action {
    max-width: 150px !important;
    padding-bottom: 5px !important;
    margin-right: 2px !important;
    margin-top: 0 !important;
  }

  .momentum-btn-text {
    margin: 0 !important;
  }

  .momentum-ribbon {
    width: 200px !important;
    margin-left: -60px;
    margin-top: 15px;
  }
}
